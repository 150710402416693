import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Link from 'utils/localized-link';

import Button from '@youship/components/objects/button';

import './styles.scss';

const TechnologyHero = () => {
  const intl = useIntl();

  return (
    <div className="technology-hero">
      <div className="container">
        <div className="row">
          <div className="col col-12 col-md-8 offset-md-2">
            <h1 className="h1--display">
              <FormattedMessage id="tech.header.title" />
            </h1>
            <p className="technology-hero__text">
              <FormattedMessage id="tech.header.subtitle" />
            </p>
            <Button
              classNames="technology-hero__button"
              linkComponent={Link}
              linkProps={{ to: '/contacts' }}
              noBackground
              noPadding
              noShadow
              text={intl.formatMessage({ id: 'tech.header.contact' })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TechnologyHero;

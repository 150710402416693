import React from 'react';

import Animation from 'components/animation';
import TechnologyTimelineEntry from 'components/technology-timeline-entry';

import mapNavigationAnimation from 'animations/navigation_map';
import deliveryAnimation from 'animations/delivery';
import satelliteAnimation from 'animations/satellite';
import fleetAnimation from 'animations/fleet';
import autonomousCarAnimation from 'animations/autonomous_car';


import './styles.scss';

const timelineEntries = [
  {
    title: 'tech.timeline.l1.title',
    text:  'tech.timeline.l1.text',
    animationProps: {
      animationData: deliveryAnimation,
      loop: false,
      autoplay: false,
      playOnViewportVisibility: true,
      width: '100%',
      height: 'auto'
    },
    imageWidth: 719,
    inverted: false
  },
  {
    title: 'tech.timeline.l2.title',
    text:  'tech.timeline.l2.text',
    animationProps: {
      animationData: satelliteAnimation,
      loop: true,
      autoplay: true,
      width: '100%',
      height: 'auto'
    },
    imageWidth: 386,
    inverted: true
  },
  {
    title: 'tech.timeline.l3.title',
    text:  'tech.timeline.l3.text',
    animationProps: {
      animationData: fleetAnimation,
      loop: true,
      autoplay: true,
      width: '100%',
      height: 'auto'
    },
    imageWidth: 480,
    inverted: false
  },
  {
    title: 'tech.timeline.l4.title',
    text:  'tech.timeline.l4.text',
    animationProps: {
      animationData: autonomousCarAnimation,
      loop: false,
      autoplay: false,
      playOnViewportVisibility: true,
      width: '100%',
      height: 'auto'
    },
    imageWidth: 358,
    inverted: true
  }
];

const TechnologyTimeline = () => (
  <div className="technology-timeline">
    <div className="container">
      <div className="row">
        <div className="col col-12">
          <div className="technology-timeline__navigation">
            <Animation
              width="100%"
              height="auto"
              animationData={mapNavigationAnimation}
              classNames="technology-timeline__navigation-image"
            />
          </div>
          <div className="technology-timeline__entries">
            {timelineEntries && timelineEntries.map((entry, index) => (<TechnologyTimelineEntry
              {...entry.animationProps}
              key={index}
              image={entry.image ? entry.image : null}
              imageWidth={entry.imageWidth}
              title={entry.title}
              text={entry.text}
              inverted={entry.inverted}
            />))}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default TechnologyTimeline;

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import Animation from 'components/animation';

import './styles.scss';

const TechnologyTimelineEntry = ({ classNames, image, imageWidth, inverted, text, title, ...animationProps }) => {
  const imageClassnames = inverted ? 'col col-12 col-lg-5 offset-lg-2' : 'col col-12 col-lg-5 order-lg-1';
  const textClassnames = inverted ? 'col col-12 col-lg-5' : 'col col-12 col-lg-5 offset-lg-2 order-lg-2';

  return (
    <div className={`technology-timeline-entry${classNames ? ` ${classNames}` : ''}`}>
      <div className="row align-items-center">
        <div className={textClassnames}>
          <div className="technology-timeline-entry__info">
            <h2 className="h2--display">
              <FormattedMessage id={title} />
            </h2>
            <p className="technology-timeline-entry__text">
              <FormattedMessage id={text} />
            </p>
          </div>
        </div>
        <div className={imageClassnames}>
          {animationProps.animationData && (
            <div className="technology-timeline-entry__image technology-timeline-entry__image--animation">
              <Animation
                {...animationProps}
                classNames="technology-timeline-entry__animation"
              />
            </div>
          )}
          {!animationProps.animationData && image && (
            <img
              src={image}
              alt={title}
              width={imageWidth}
              className="technology-timeline-entry__image"
            />
          )}
        </div>
      </div>
    </div>
  );
};

TechnologyTimelineEntry.propTypes = {
  classNames: PropTypes.string,
  image: PropTypes.string,
  imageWidth: PropTypes.number,
  inverted: PropTypes.bool,
  text: PropTypes.string,
  title: PropTypes.string
};

TechnologyTimelineEntry.defaultProps = {
  classNames: null,
  image: null,
  imageWidth: 0,
  inverted: false,
  text: '',
  title: ''
};

export default TechnologyTimelineEntry;
